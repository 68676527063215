import React from 'react';
import { Col } from 'reactstrap';

const Identity = () => {
    return (
        <Col>
            <h1>Tom Leidy</h1>
            <h2>Software Engineer</h2>
            <p>Pursuing purpose, elegant code, and new horizons</p>
            <p>Remote <span className='color-middle'>||</span> San Francisco, CA <span className='color-middle'>||</span> SE
                Pennsylvania</p>
        </Col>


    )
}

export default Identity;