import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Identity from './components/Identity';
import About from './components/About';
import Socials from './components/Socials';
import Projects from './components/Projects';
import Experience from './components/Experience';
import Resume from './components/Resume';
import Design from './components/Design';
import Curiosity from './components/Curiosity';

/*
To do:
- Accessibility / Aria labels social links
- Resume (long form)
 */


const Nav = () => { }


function App() {
  return (
    <Container fluid className='my-1 py-1'>
      <Row>
        <Col xs='12' md='6' lg='4' className='sticky-left-column'>
          <Row>
            <Identity />
          </Row>
          <Row className='py-4 pt-md-up'>
            <Socials />
          </Row>
        </Col>
        <Col xs='12' md='6' lg='8' className='mt-1 mb-5'>
          <Row className='pt-2'>
            <About />
          </Row>
          <Row className='py-4'>
            <Curiosity />
          </Row>
          <Row className="py-4">
            <Projects />
          </Row>
          <Row className="py-4">
            <Experience />
          </Row>
          <Row className="py-4">
            <Resume />
          </Row>
          <Row className="py-4">
            <Design />
          </Row>
        </Col>
      </Row>

    </Container>
  );
}









export default App;
