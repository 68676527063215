import React from "react";
import { Col } from "reactstrap";


const Curiosity = () => {
    return (
        <Col>
            <h2>Curiosities</h2>
            <p>
                Currently reading: <a href="https://www.goodreads.com/book/show/3735293-clean-code">Clean Code</a> by Robert C. Martin
            </p>
            <p>
                Long term learning desires:
            </p>
            <ul>
                <li>Rust (memory safe? I’m curious)</li>
                <li>Swift (I have ideas for apps I'd like to use!)</li>
                <li>Linear algebra (for ML/AI)</li>
                <li>Permaculture (to connect with nature in a homey kind of way)</li>
            </ul>
        </Col>
    )
}

export default Curiosity;