import React from 'react';
import { Col } from 'reactstrap';
import { about } from '../content';

const About = () => {
    return (
        <Col>
            <h2>About</h2>
            {about.map((line, idx) => <p key={"about" + idx}>{line}</p>)}
        </Col>
    )

}


export default About;