import React from "react";
import { Col } from "reactstrap";

/* Credits:
* Favicon and Yi-ren-tai icons: https://favicon.io/
* tap-space and avocado home icons: https://game-icons.net/
* Base for spreadsheet table and hand money exchange icon: https://uxwing.com/
* Base for acupuncture icon: https://www.svgrepo.com/
* Editing of SVG done with Inkscape
* Laid out with VS Code, best viewed in Chrome in Dark Mode.
*/

const Design = () => {
    return (
        <Col>
            <h2>Design & Attribution</h2>
            <p>Best viewed in Dark Mode.</p>
            <p>Designed and built in React with Bootstrap, FontAwesome, and Inkscape using VS Code.</p>
            <p>Tap-space and avocado home SVGs from <a href="https://game-icons.net/">game-icons.net</a>. Favicon and yi-ren icons generated via <a href="https://favicon.io/">favicon.io</a>. Table and hand money exchange icons from <a href="https://uxwing.com/">uxwing.com</a>. Base icon for acupuncture needles from <a href="https://www.svgrepo.com/">SVG Repo</a>.</p>
        </Col>
    )
}

export default Design;