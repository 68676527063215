import React from "react";
import { Row, Col } from "reactstrap";
import { Card, Badge } from 'reactstrap';
import { projects } from "../content";

const Projects = () => {
    return (
        <Col>
            <h2>Projects</h2>
            <ProjectList data={projects} />
        </Col>
    )

}

const ProjectList = ({ data }) => {
    return (
        <div className='py-1'>
            {data && data.map((item, idx) => <ProjectItem item={item} key={"proj-" + idx} />)}
        </div>
    )
}

const ProjectItem = ({ item }) => {
    let { icon, title, description, alt, technologies, url } = item;
    return (
        <Card className='card-entry'>
            <Row className='align-items-center mb-4'>
                <Col xs="3" md="2"><img alt={alt} src={icon} className='icon m-2' /></Col>
                <Col xs="9" md="1" lg="2">
                    <a href={url}>{title}</a>
                </Col>
                <Col>
                    <Row>
                        <Col className="m-1">{description}</Col>
                    </Row>
                    <Row>

                        {technologies && technologies.map((tech, idx) => (<Col className="m-1" key={"techpill-" + idx} xs="auto"><Badge pill>{tech}</Badge></Col>))}
                    </Row>
                </Col>
            </Row>

        </Card>
    )
}

export default Projects;