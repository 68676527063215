import React from "react";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { socials } from '../content';

const Socials = () => {
    return (
        <Col>
            <h2>Socials</h2>
            <Row className="py-1">
                {socials && socials.map((item, idx) => <SocialItem data={item} key={"social-" + idx} />)}
            </Row>
        </Col>
    )
}

const SocialItem = ({ data }) => {
    let { url, icon } = data;
    return (
        <Col xs="auto">
            <a href={url}>
                <FontAwesomeIcon icon={icon} className='fa-2xl mx-1 social' />
            </a>
        </Col>
    )
}

export default Socials;