import React from "react";
import { Card, Row, Col } from 'reactstrap';
import { experience } from "../content";

const Experience = () => {
    return (
        <Col>

            <h2>Experience</h2>
            <ExperienceList data={experience} />
        </Col>
    )
}

const ExperienceList = ({ data }) => {
    return (
        <div>
            {data && data.map((item, idx) => <ExperienceItem item={item} key={"xp-" + idx} />)}
        </div>
    )
}

const ExperienceItem = ({ item }) => {
    let { icon, title, description, alt, company } = item;
    return (
        <Card className='card-entry'>
            <Row className='align-items-center mb-4'>
                <Col xs="3" md="2"><img alt={alt} src={icon} className='icon m-2' /></Col>
                <Col xs="9" md="2">
                    <Row>
                        <Col>{title}</Col>
                    </Row>
                    <Row>
                        <Col className='color-middle'>{company}</Col>
                    </Row>
                </Col>
                <Col>{description}</Col>
            </Row>
        </Card>
    )
}

export default Experience;